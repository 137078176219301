import React, { useContext, useEffect } from "react"
import Img from "gatsby-image"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Image from "../configurable/Image"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import Grid from "../configurable/Grid"
import { filterProductsBy, filterProductsByAny, markdownNodesFilter } from "~/utils"
import Col9 from "../grid/Col9"
import Col4 from "../grid/Col4"
import Col8 from "../grid/Col8"
import Col11 from "../grid/Col11"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Modal from 'react-modal';
import "./new-prods.scss"
import "./mounting-info.scss"
import Col3 from "../grid/Col3"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import Col2 from "../grid/Col2"
import FormInput from "../olc-framework/FormInput"
import FormSelect from "../olc-framework/FormSelect"
import { GetModal } from "../configurable/Modal"
import Expandable from "../configurable/Expandable"
import "./bolds.scss"


const mountingInfo = ({ markdownNodes }) => {
  const productsBlockRef = React.createRef() as React.RefObject<HTMLElement>

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);

  const [hasUsedFilters, setHasUsedFilters] = React.useState(false);


  return (
    <div>
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} >
          Our Products
        </Heading>
      </Hero>
      <Block >
        <Section>
          <div className="container container--column illustrated-house-block">
            <Heading >Mounting Kits</Heading>
            <p className="bold-formatting" >
            When it comes to installing Solar Panels, it’s essential to know what type of roof you have to ensure that the correct mounting kit is provided along with your Solar Panels. As reassurance, we offer a <strong >free Survey,</strong>  whereby our surveyors take note of all the essential materials and measurements. There is a variety of different mounting frames used and Green Energy Together work with a range of leading suppliers and manufacturers that enable our installations.
            </p>
            <p className="bold-formatting" >Just click to jump to the corresponding section</p>
            <div className="mounting-ills row" >
              <div className="mounting-ills__item">
                <img className="anim_pop" src={require('../../images/mounting/mid.png')} alt="my image"  />
                <BlockCTA className="blue-btn" url="/mounting-info#pitchroofs">
                  Pitched Roofs
                </BlockCTA>
              </div>
              <div className="mounting-ills__item">
                <img src={require('../../images/mounting/flat.png')} alt="my image"  />
                <BlockCTA className="blue-btn" url="/mounting-info#flatroofs">
                  Flat Roofs
                </BlockCTA>
              </div>
              <div className="mounting-ills__item">
                <img src={require('../../images/mounting/groundm.png')} alt="my image"  />
                <BlockCTA className="blue-btn" url="/mounting-info#groundmount">
                  Groundmount
                </BlockCTA>
              </div>
            </div>
          </div>

        </Section>     
        <div className="pitchroofs" id="pitchroofs">
          <Section>
            <div className="row" >
              <Col11>
                <Heading level={3} >Pitched Roofs</Heading>
              </Col11>
            </div>
            <div className="row reverse-cols" style={{marginTop:40}}>
              <Col3 className="left_bottom" >
                <img src={require('../../images/mounting/Picture10.png')} alt="" />
              </Col3>
              <Col9 className="right_top" >
                <Heading level={4} underlined >Tiled & Slate Roofs – Rail Mounting </Heading>
                <p className="bold-formatting" >
                Rail mounting is a commonly used method for mounting solar panels to <strong >tiled and slate roofs</strong>. It is a <strong >cost-effective</strong>  solution whereby rails; normally aluminium, are bolted into the roof structure and then sealed to prevent water ingress. 
                </p>
                <p className="bold-formatting" >
                          The panels are fitted onto the rails in either portrait or landscape, aspect making this the most flexible mounting system on offer. 
                          </p>

               
              </Col9>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols">
              <Col9>
                <Heading level={4} underlined >In-roof </Heading>
                <p className="bold-formatting" >
                
                In-roof panels are used in <strong >new builds or re-roofs</strong>  with the panels installed in trays that are almost lying flush against the surface of the roof instead of on top of the roof tiles.
                </p>

              </Col9>
              <Col3>
              <img src={require('../../images/mounting/Picture3.jpg')} alt="" />
              </Col3>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols">
              <Col3 className="left_bottom">
                <img src={require('../../images/mounting/Picture4.jpg')} alt="" />
              </Col3>
              <Col9 className="right_top">
                <Heading level={4} underlined >Metal trapezoidal roofs </Heading>
                <p className="bold-formatting" >
                Metal trapezoidal roof structures are a common roof type for <strong >commercial buildings</strong>  in the UK. The mounting kit consists of rails; that are secured, <strong >to the ridges of a metal roof </strong> using self-tapping screws, with a waterproof membrane installed at the base of the rails to ensure a secure weather seal.   
                </p>
                <p className="bold-formatting" >
                          The Solar PV panels are clamped to the rails and as the panels are installed very close to the roof this minimises wind loading.
                          </p>

     
              </Col9>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols">
              <Col9>
                <Heading level={4} underlined >Metal Standing Seam roofs </Heading>
                <p className="bold-formatting" >
                Standing seam roofing systems are generally used on metal roofs and are available in many metal materials such as zinc, steel, lead and copper. 
                </p>
                <p className="bold-formatting" >
                          Usually <strong >installed with clamps</strong>  that are attached to the standing seam; of the roof, with either a rail attached to the clamps or the solar PV panels are fixed directly to the standing seam clamps of the roof.
                          </p>

              </Col9>
              <Col3>
              <img src={require('../../images/mounting/Picture1.jpg')} alt="" />
              </Col3>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols">
            <Col3 className="left_bottom">
              <img src={require('../../images/mounting/Picture11.jpg')} alt="" />
              </Col3>
              <Col9 className="right_top">
                <Heading level={4} underlined >Solar PV Tiles & Slates </Heading>
                <p className="bold-formatting" >
                These are mini solar panels that are designed to <strong > match your existing tiles</strong>. This allows a solar PV system to be installed without impacting the aesthetics of the building. 
                </p>
                <p className="bold-formatting" >
                          This is also an ideal solution; for listed properties or conservation areas, where planning permission makes it harder to install conventional solar panels.
                          </p>

              </Col9>

            </div>
          </Section>
        
        
        
          <div className="divider" />
          </div>  
        <div className="flatroofs" id="flatroofs" >
          <Section>
            <div className="row" >
              <Col11>
                <Heading level={3} >Flat Roofs</Heading>
              </Col11>
            </div>
            <div className="row reverse-cols" style={{marginTop:40}}>
              <Col9>
                <Heading level={4} underlined >East/West mounting systems</Heading>
                <p className="bold-formatting" >
                There are many benefits for installing a PV array in an East/West orientation such as, reducing the amount of ballast needed for the mounting frame as the panels are installed back-back, which also <strong > reduces wind resistance. </strong>
                </p>
                <p className="bold-formatting" >
                          This means extra panels can be installed in the same space, and in turn, this increases the amount of energy that be generated compared to a south-facing PV system.
                          </p>

              </Col9>
              <Col3>
              <img src={require('../../images/mounting/Picture7.jpg')} alt="" />
              </Col3>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols">
              <Col3 className="left_bottom">
              <img src={require('../../images/mounting/Picture8.jpg')} alt="" />
              </Col3>
              <Col9 className="right_top">
                <Heading level={4} underlined >Flat trapezoidal roofs </Heading>
                <p className="bold-formatting" >
                This is Installed the same as a pitched <strong >trapezoidal mounting system</strong>, with the pitch of the frame to be set at an angle for optimum performance.
                </p>

                <p className="bold-formatting" >
                The mounting kit consists of rails; that are secured, to the ridges of a metal roof using self-tapping screws, with a waterproof membrane installed at the base of the rails to ensure a secure weather seal. The Solar PV panels are <strong >clamped to the rails</strong> and as the panels are installed very close to the roof this minimises wind loading.

                </p>

              </Col9>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols">
              <Col9>
                <Heading level={4} underlined >Ballasted south-facing flat roof mounts</Heading>
                <p className="bold-formatting" >
                For buildings where there <strong >is a large area of roof space</strong>  and where penetration into the roof to secure the mounting isn’t possible; south-facing ballasted systems are used. 
                </p>
                <p className="bold-formatting" >
                  Whilst this solution will increase the generation potential of the PV system compared to the same number of panels in an East/West system, there is an increase in cost due to the number of ballast required to secure the mounting frame to the roof. The panels also need to be spaced further apart to avoid shading.
                </p>
                <BlockCTA external left secondary url={`https://get-uk-docs.netlify.app/BA2_flat_roof_kit.pdf`} right arrow="right">
                  Learn more
                </BlockCTA>

              </Col9>
              
            <Col3>
              <img src={require('../../images/mounting/Picture9.jpg')} alt="" />
              </Col3>
            </div>
          </Section>
        </div>  
        <div className="divider" />
        <div className="groundmount" id="groundmount">
          <Section>
            <div className="row" >
              <Col11>
                <Heading level={3} >Groundmount</Heading>
              </Col11>
            </div>
            <div className="row reverse-cols" style={{marginTop:30}}>
            <Col3 className="left_bottom">
              <img src={require('../../images/mounting/Picture12.jpg')} alt="" />
              </Col3>
              <Col9 className="right_top">
                <Heading level={4} underlined >Metal frames</Heading>
                <p className="bold-formatting" >
                Commonly used for <strong >commercial solar farms</strong> , where metal frames are typically set in concrete bases with the panels installed in a variety of layouts such as horizontally (2 panels) or vertical (1 panel).
                </p>

              </Col9>

            </div>
          </Section>
          <Section>
            <div className="row reverse-cols">

              <Col9>
                <Heading level={4} underlined >Mono-pole metal frames </Heading>
                <p className="bold-formatting" >
                Where the upright posts are <strong >pile driven</strong>  into the ground, which saves on cost by saving on concrete as well as, reducing the footprint of the array.
                </p>

              </Col9>
              <Col3>
              <img style={{width:"100%"}} src={require('../../images/mounting/Picture13.jpg')} alt="" />
              </Col3>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols">
            <Col3 className="left_bottom">
              <img  style={{width:"100%"}}  src={require('../../images/mounting/Picture14.png')} alt="" />
              </Col3>
              <Col9 className="right_top">
                <Heading level={4} underlined >Solar Car Ports</Heading>
                <p className="bold-formatting" >
                Solar carports are <strong >ground-mounted canopy</strong>  installations that stretch out over car parking spaces. The canopy roof area is slightly tilted and offers a perfect platform for solar panels to be seamlessly mounted, which also adds to the aesthetics of the structure, charging ports can be added to the system for electric cars. 
                </p>
              </Col9>
              

            </div>
          </Section>
        </div>  
        {/* <div className="divider" />
        <Section>
          <div className="container " style={{marginTop:80}}>
            <Heading level={2} >Our Manufacturers</Heading>
            <p style={{color:"red"}}>
            Green Energy Together supplies a wide range of safety compliant and robust mounting hardware, providing solutions for a vast amount of applications and roof coverings. 
            </p>
          </div>
          <div className="tech-type" style={{paddingTop:50}} >
            <div className="row">
              <Col11>
                <Heading level={3} >Renusol</Heading>
              </Col11>
            </div>
            <div className="row ">
              <Col12>
                <p style={{color:"red"}}>
                With more than 20 years of experience, Renusol is specialised in the manufacture and sales of solar mounting systems for all types of roof and was founded in 1997. From its headquarters in Cologne, the company manages its businesses in more than 48 countries.
                </p>
              </Col12>
            </div>
            <div className="row" style={{marginTop:30}}>
              <Col8>
                <p style={{color:"red"}}>
                We are proud to make the Renusol products available through our company. Our experienced technicians advise you, our online-tools are comfortable and transparent, and we deliver reliable installations with extensive warranty protections. 
                </p>
                <BlockCTA secondary url={`/static/7e19a0ac2596094b38dea005e723dc9b/renusol-product-catalogue.pdf`} arrow="right">
                      View Renusol Catalogue
                </BlockCTA>
              </Col8>
              <Col4 style={{ alignSelf: "none" }}>
                <img src={require('../../images/brands/renusol.png')} alt="" />
              </Col4>
            </div>
          </div>
         
        </Section>  */}

        
      </Block>
    </div>
  )
}

export default mountingInfo
